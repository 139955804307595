const images = {
    Header:[
        require("../assets/image-max/background.jpg").default,
        require("../assets/image-max/secondbackground.jpg").default,
        require("../assets/image-max/thirdbackground.jpg").default
    ],
    svg: [
        require("../assets/svg/phone-marker.svg").default,
        require("../assets/svg/mail-marker.svg").default,
        require("../assets/svg/map-marker.svg").default
    ],
    Main:{
        Article:[
            require("../assets/image-min/1.jpg").default,
            require("../assets/image-min/2.jpg").default,
            require("../assets/image-min/3.jpg").default,
            require("../assets/image-min/4.jpg").default,
            require("../assets/image-min/5.jpg").default,
            require("../assets/image-min/6.jpg").default
        ],
        Gallery:{
            Facility: {
                min:[
                    require("../assets/image-min/to1.jpg").default,
                    require("../assets/image-min/to2.jpg").default,
                    require("../assets/image-min/to3.jpg").default,
                    require("../assets/image-max/to0_wide.jpg").default,
                    require("../assets/image-min/to4.jpg").default,
                    require("../assets/image-min/to5.jpg").default,
                    require("../assets/image-min/to6.jpg").default,
                    require("../assets/image-min/to7.jpg").default,
                    require("../assets/image-min/to8.jpg").default,
                    require("../assets/image-min/to9.jpg").default,
                    require("../assets/image-min/to10.jpg").default,
                    require("../assets/image-min/to11.jpg").default,
                    require("../assets/image-min/to12.jpg").default,
                    require("../assets/image-min/to13.jpg").default,
                    require("../assets/image-min/to14.jpg").default,
                    require("../assets/image-min/to15.jpg").default,
                    require("../assets/image-min/to16.jpg").default,
                    require("../assets/image-min/to17.jpg").default,
                    require("../assets/image-min/to18.jpg").default,
                    require("../assets/image-min/cot13_top-oriented.jpg").default,
                    require("../assets/image-min/cot14_top-oriented.jpg").default,
                    require("../assets/image-min/cot15_top-oriented.jpg").default
                ],
                max:[
                    require("../assets/image-max/to1.jpg").default,
                    require("../assets/image-max/to2.jpg").default,
                    require("../assets/image-max/to3.jpg").default,
                    require("../assets/image-max/to0_wide.jpg").default,
                    require("../assets/image-max/to4.jpg").default,
                    require("../assets/image-max/to5.jpg").default,
                    require("../assets/image-max/to6.jpg").default,
                    require("../assets/image-max/to7.jpg").default,
                    require("../assets/image-max/to8.jpg").default,
                    require("../assets/image-max/to9.jpg").default,
                    require("../assets/image-max/to10.jpg").default,
                    require("../assets/image-max/to11.jpg").default,
                    require("../assets/image-max/to12.jpg").default,
                    require("../assets/image-max/to13.jpg").default,
                    require("../assets/image-max/to14.jpg").default,
                    require("../assets/image-max/to15.jpg").default,
                    require("../assets/image-max/to16.jpg").default,
                    require("../assets/image-max/to17.jpg").default,
                    require("../assets/image-max/to18.jpg").default,
                    require("../assets/image-max/cot13_top-oriented.jpg").default,
                    require("../assets/image-max/cot14_top-oriented.jpg").default,
                    require("../assets/image-max/cot15_top-oriented.jpg").default
                    ]
            },
            Cottages: {
                min:[
                    require("../assets/image-min/cot1.jpg").default,
                    require("../assets/image-min/cot2.jpg").default,
                    require("../assets/image-min/cot3.jpg").default,
                    require("../assets/image-min/cot4.jpg").default,
                    require("../assets/image-min/cot5.jpg").default,
                    require("../assets/image-min/cot6.jpg").default,
                    require("../assets/image-min/cot7.jpg").default,
                    require("../assets/image-min/cot8.jpg").default,
                    require("../assets/image-min/cot9.jpg").default,
                    require("../assets/image-min/cot10.jpg").default,
                    require("../assets/image-min/cot11.jpg").default,
                    require("../assets/image-min/cot12.jpg").default,
                    require("../assets/image-min/cot13_top-oriented.jpg").default,
                    require("../assets/image-min/cot14_top-oriented.jpg").default,
                    require("../assets/image-min/cot15_top-oriented.jpg").default
                ],
                max:[
                    require("../assets/image-max/cot1.jpg").default,
                    require("../assets/image-max/cot2.jpg").default,
                    require("../assets/image-max/cot3.jpg").default,
                    require("../assets/image-max/cot4.jpg").default,
                    require("../assets/image-max/cot5.jpg").default,
                    require("../assets/image-max/cot6.jpg").default,
                    require("../assets/image-max/cot7.jpg").default,
                    require("../assets/image-max/cot8.jpg").default,
                    require("../assets/image-max/cot9.jpg").default,
                    require("../assets/image-max/cot10.jpg").default,
                    require("../assets/image-max/cot11.jpg").default,
                    require("../assets/image-max/cot12.jpg").default,
                    require("../assets/image-max/cot13_top-oriented.jpg").default,
                    require("../assets/image-max/cot14_top-oriented.jpg").default,
                    require("../assets/image-max/cot15_top-oriented.jpg").default
                    ],
            },
            Spa: {
                min:[
                    require("../assets/image-min/spa1.jpg").default,
                    require("../assets/image-min/spa2.jpg").default,
                    require("../assets/image-min/spa3.jpg").default
                ],
                max:[
                    require("../assets/image-max/spa1.jpg").default,
                    require("../assets/image-max/spa2.jpg").default,
                    require("../assets/image-max/spa3.jpg").default
                ],
            },
            Rooms: {
                min:[
                    [require("../assets/image-min/pok1-1.jpg").default,
                    require("../assets/image-min/pok4-1.jpg").default,
                    require("../assets/image-min/pok2-1.jpg").default,
                    require("../assets/image-min/pok1-2_top-oriented.jpg").default,
                    require("../assets/image-min/pok4-2.jpg").default,
                    require("../assets/image-min/pok3-1_top-oriented.jpg").default],
                    [require("../assets/image-min/pok10-1.jpg").default,
                    require("../assets/image-min/pok10-2_top-oriented.jpg").default,
                    require("../assets/image-min/pok10-3.jpg").default,
                    require("../assets/image-min/pok12-1.jpg").default],
                    [require("../assets/image-min/pok6-1.jpg").default,
                    require("../assets/image-min/pok6-2.jpg").default,
                    require("../assets/image-min/pok6-3.jpg").default,
                    require("../assets/image-min/pok7-1.jpg").default,
                    require("../assets/image-min/pok7-2.jpg").default,
                    require("../assets/image-min/pok7-3.jpg").default],
                    [ require("../assets/image-min/pok8-1.jpg").default,
                    require("../assets/image-min/pok8-2.jpg").default,
                    require("../assets/image-min/pok8-3.jpg").default,
                    require("../assets/image-min/pok10-2_top-oriented.jpg").default,
                    require("../assets/image-min/pok9-2.jpg").default,
                    require("../assets/image-min/pok9-1.jpg").default,
                    require("../assets/image-min/pok8-5.jpg").default,
                    require("../assets/image-min/pok9-3.jpg").default],
                    [require("../assets/image-min/pok11-1.jpg").default,
                    require("../assets/image-min/pok11-2.jpg").default,
                    require("../assets/image-min/pok11-3.jpg").default]
                ],
                max:[
                    [require("../assets/image-max/pok1-1.jpg").default,
                    require("../assets/image-max/pok4-1.jpg").default,
                    require("../assets/image-max/pok2-1.jpg").default,
                    require("../assets/image-max/pok1-2_top-oriented.jpg").default,
                    require("../assets/image-max/pok4-2.jpg").default,
                    require("../assets/image-max/pok3-1_top-oriented.jpg").default],
                    [require("../assets/image-max/pok10-1.jpg").default,
                    require("../assets/image-max/pok10-2_top-oriented.jpg").default,
                    require("../assets/image-max/pok10-3.jpg").default,
                    require("../assets/image-max/pok12-1.jpg").default],
                    [require("../assets/image-max/pok6-1.jpg").default,
                    require("../assets/image-max/pok6-2.jpg").default,
                    require("../assets/image-max/pok6-3.jpg").default,
                    require("../assets/image-max/pok7-1.jpg").default,
                    require("../assets/image-max/pok7-2.jpg").default,
                    require("../assets/image-max/pok7-3.jpg").default],
                    [ require("../assets/image-max/pok8-1.jpg").default,
                    require("../assets/image-max/pok8-2.jpg").default,
                    require("../assets/image-max/pok8-3.jpg").default,
                    require("../assets/image-max/pok10-2_top-oriented.jpg").default,
                    require("../assets/image-max/pok9-2.jpg").default,
                    require("../assets/image-max/pok9-1.jpg").default,
                    require("../assets/image-max/pok8-5.jpg").default,
                    require("../assets/image-max/pok9-3.jpg").default],
                    [require("../assets/image-max/pok11-1.jpg").default,
                    require("../assets/image-max/pok11-2.jpg").default,
                    require("../assets/image-max/pok11-3.jpg").default]
                ]
            }
        }
    }
  };

  export default images;